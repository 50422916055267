body{
    margin:0px;
    padding:0px;
    background-color: #041032;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/MoFo_2024_Holiday_Card_Mobile_Video_Final_1500x2668.jpg);

    @media screen and (min-width: 1801px) {
        background-image: url(../img/MoFo_2024_Holiday_Card_Desktop_Background_2880x1800.png); 
    }

    @media screen and (min-width: 1125px) and (max-width: 1800px) {
        background-image: url(../img/MoFo_2024_Holiday_Card_Desktop_Background_2880x1800.png); 
    }
}

*{
    box-sizing: border-box;
}
#logo {
	height:32px;
}
.page{
    &--container{
        width:calc(100% - 110px);
        min-height:100vh;
        position: relative;
        margin:auto;

        @media screen and (max-width:$mobile){
            width:100%;
            padding:0px;
        }
    }
}

.mobile--only{
    @media screen and (min-width:$mobile + 1px){
        display: none;
    }
}
.video--wrapper {
	iframe {
		border: 0;
	}
	div {
		display: none; 

		&#US {
			display: block;
		}
	}
}