@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
      url('../fonts/opensans-light-webfont.woff2') format('woff2'),
      url('../fonts/opensans-light-webfont.woff') format('woff'),
      url('../fonts/opensans-light-webfont.ttf') format('truetype'),
      url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'karlaregular';
    src: url('../fonts/karla-regular-webfont.eot');
    src: url('../fonts/karla-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('../fonts/karla-regular-webfont.woff2') format('woff2'),
      url('../fonts/karla-regular-webfont.woff') format('woff'),
      url('../fonts/karla-regular-webfont.ttf') format('truetype'),
      url('../fonts/karla-regular-webfont.svg#karlaregular') format('svg');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'wisdom_scriptregular';
    src: url('../fonts/wisdom-script-webfont.eot');
    src: url('../fonts/wisdom-script-webfont.eot?#iefix') format('embedded-opentype'),
      url('../fonts/wisdom-script-webfont.woff2') format('woff2'),
      url('../fonts/wisdom-script-webfont.woff') format('woff'),
      url('../fonts/wisdom-script-webfont.ttf') format('truetype'),
      url('../fonts/wisdom-script-webfont.svg#wisdom_scriptregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'helveticaneuebold';
    src: url('../fonts/helvetica_neu_bold-webfont.woff2') format('woff2'),
      url('../fonts/helvetica_neu_bold-webfont.woff') format('woff'),
      url('../fonts/helvetica_neu_bold-webfont.ttf') format('truetype'),
      url('../fonts/helvetica_neu_bold-webfont.svg#helveticaneuebold') format('svg');
    font-weight: normal;
    font-style: normal;
  
  }