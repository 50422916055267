.video{
    &--wrapper{
        margin:auto;
        position:relative;
        display: block;
        width:100%;
        max-width:960px;
    }

    @media screen and (max-width:$mobile){
        padding:0px 30px;
        position: absolute;
        top: 50%;
        left:50%;
        transform:translate(-50%,-50%);  
        width:100%; 
    }
}