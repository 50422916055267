.header{
    margin:65px auto;
    position: relative;
    display: block;
    text-align: center;
    @media screen and (max-width:$landscape){
        text-align: left;
    }

    @media screen and (max-width:$mobile){
        margin:0px auto;
        padding:0px 30px;
    }

    &--logo{
        position: relative;
        z-index:999;
        display: inline-block;

        @media screen and (max-width:$mobile){
            margin-top:40px;
        }

        img{
            width:100%;
            max-width:172px;
        }
    }

    &--lang{
        position:absolute;
        top:0px;
        right:0px;
        display: flex;
        align-items: center;
        column-gap: 22px;
        color:$white;
        font-family: 'helveticaneuebold';
        line-height: 100%;
        z-index:998;

        @media screen and (max-width:$mobile){
            position:fixed;
            top:-101vh;
            width:100%;
            background-color: $blue;
            height:100vh;
            padding-top:140px;
            display: block;
            text-align: center;
            transition:top .2s ease-out;

            .header--lang--label,.header--lang--select{
                opacity:0;
                transition: opacity .2s linear;
                transition-delay: 0s;
            }
            

            &.mobile--open{
                top:0px;
                .header--lang--label,.header--lang--select{
                    opacity:1;
                    transition-delay: .15s;
                }
            }
        }

        &--label{
            text-transform: uppercase;
            font-size:8px;

            @media screen and (max-width:$mobile){
                font-size:22px;
                letter-spacing: 2px;
            }
        }

        &--select{
            width:160px;
            font-family: 'open_sanslight';
            text-align: left;
            position: relative;

            @media screen and (max-width:$mobile){
                width:100%;       
            }

            a{
                font-size:12px;
                padding:10px 44px 10px 20px;
                display: block;
                display: block;
                text-decoration: none;

                @media screen and (max-width:$mobile){
                    font-size:18px;   
                    padding:0px;
                    margin-top:38px;
                }
            }

            div.selected{

                @media screen and (max-width:$mobile){
                    display: none;
                }

                a{
                    color:$white;

                    &:after{
                        width:4px;
                        height:4px;
                        content:'';
                        position:absolute;
                        right:40px;
                        top:15px;
                        border-right:1px solid $white;
                        border-bottom:1px solid $white;
                        transform:rotate(45deg);
                    }
                }
            }   

            &--open{
                div.selected{

                    &+ul{
                        max-height: 300px;
                        opacity:1;
                    }
                }
            }
            ul{
                padding:0px;
                margin:0px;
                display: block;
                max-height:0px;
                opacity:0;
                overflow: hidden;
                list-style-type: none;
                position: absolute;
                top:0px;
                width:100%;
                transition:max-height .2s linear;

                @media screen and (max-width:$mobile){
                    position: relative;
                    max-height: unset;
                    opacity:1;
                    width:100%;
                }

                li{
                    margin-bottom:1px;
                    display: block;
                    @media screen and (max-width:$mobile){
                        text-align: center;
                    }

                    &.selected{
                        @media screen and (max-width:$mobile){
                            display: none;
                        }
                    }

                    a{
                        background-color: $blue;
                        color:$white;

                        @media screen and (max-width:$mobile){
                            background-color: transparent;
                            display: inline-block;
                        }

                        &:hover{
                            @media screen and (min-width:$mobile + 1px){
                                background-color: $white;
                                color:$black;
                            }
                        }
                    }

                    &.active{

                        @media screen and (min-width:$mobile + 1px){
                            display: none;
                        }

                        @media screen and (max-width:$mobile){
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .hamburger{
        position:absolute;
        top:40px;
        right:30px;
        width:34px;
        height:34px;
        cursor: pointer;
        z-index:999;

        span{
            position: absolute;
            left:0px;
            width: 100%;
            height: 2px;
            transform-origin: center;
            background-color: #a2a7b3;
            transition: all .1s linear;

            &:first-child{
                top:3px;
            }
            &:nth-child(2){
                top:calc(50% - 1px);
            }
            &:last-child{
                top:29px;
            }
        }

        &--close{
            span{
                background-color: $white;
                &:first-child{
                    top:14px;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity:0;
                }
                &:last-child{
                    top:14px;
                    transform: rotate(-45deg);
                }
            }
        }

        


    }
}